import consumer from "./consumer"
import PricingCount from "../components/pricing_count.js.erb";

const pricingChannel = consumer.subscriptions.create("PricingChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log('pricing received:' + data);
    if(data.type == 'apply') {
      console.log('pricing received:apply:' + data.apply);
      const PricingCount = require('../components/pricing_count.js.erb');
      new PricingCount().applied(data);
    }
    else if(data.type == 'answer') {
      console.log('pricing received:answer:' + data.answer);
      const PricingCount = require('../components/pricing_count.js.erb');
      new PricingCount().answered(data);
    }
  }

});

