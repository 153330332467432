class SellCount {
    constructor() {
        this.sellAlarmId = null;
    }
    received(data) {
        console.log('SellCount:received:' + data);
        let result, shopId;
        //対象のelementがなければスルー
        if ($('#sell-inquiry-alarm').length === 0) {
            return;
        }
        shopId = parseInt($('#login-staff').data('shopId'));
        result = data[shopId];
        console.log('updateSellAlarm: shopId=' + shopId);

        let sellCount = 0;
        let reapproachCount = 0;
        if (result.count) {
            sellCount = result.count.sell_inquiry;
            reapproachCount = result.count.sell_inquiry_no_approach;
        }
        this.checkSellInquiryAlarm(sellCount);
        this.updateSellInquiryIcon(sellCount);
        this.updateSellInquiryNoApproachIcon(reapproachCount);
        this.showCountAlert();
    }
    checkSellInquiryAlarm(sellCount) {
        if (sellCount > 0) {
            if (!this.sellAlarmId) {
                let chk = this.startSellAlarm();
                console.log('checkSellInquiryAlarm: sellAlarmId=' + chk);
                this.sellAlarmId = chk;
            }
        } else {
            if (this.sellAlarmId) {
                clearInterval(this.sellAlarmId);
                this.sellAlarmId = null;
            }
        }
    }

    updateSellInquiryIcon(sellCount) {
        console.log('sellCount', sellCount);
        $('#sell-inquiry-alarm').setGarageNavHeaderIconBadge(sellCount);
    };

    updateSellInquiryNoApproachIcon(reapproachCount) {
        console.log('reapproachCount', reapproachCount);
        $('#sell-inquiry-no-approach-alarm').setGarageNavHeaderIconBadge(reapproachCount);
    };

    startSellAlarm() {
        console.log('startSellAlarm');
        this.playSellAlarm();
        return setInterval(this.playSellAlarm, 10000);
    };

    playSellAlarm() {
        if ($('.icon-alarm-ban').css('display') !== 'none') {
            return;
        }
        if ($('#sell-inquiry-alarm').text().trim() == '0' && this.sellAlarmId) {
            clearInterval(this.sellAlarmId);
            this.sellAlarmId = null;
            return;
        }
        window._chime2 || (window._chime2 = new Audio("/assets/chime_2-7ae7feb7cc2079ddfaa9e37c04c37d2179d472b355b8df9f0563d9a5c2511867.mp3"));
        window._chime2.currentTime = 0;
        window._chime2.play().catch(error => console.log(error));
    };

    showCountAlert() {
        if($('.navbar-header-icon-sell').is(':visible') && $('#sell-inquiry-alarm').text().trim() != '0') {
            $('.count-alert').hide('slide', {direction: 'right'});
            let topSell = $('.navbar-header-icon-sell').position().top + 50;
            $('.count-alert-sell').css('top', topSell + 'px').show('slide', {direction: 'right'});
        }
        else {
            $('.count-alert-sell').hide('slide', {direction: 'right'});
        }
    }
}
module.exports = SellCount