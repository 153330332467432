class PricingCount {
    constructor() {
        this.pricingAlarmId = null;
        this.pricedAlarmId = null;
    }
    applied(data) {
        console.log('PricingCount:applied:' + data.type);
        //対象のelementがなければスルー
        if ($('#pricing-alarm').length === 0) {
            return;
        }
        let pricingCount = parseInt(data.count);
        this.checkPricingAlarm(pricingCount);
        this.updatePricingIcon(pricingCount);
        this.showCountAlert();
    }

    checkPricingAlarm(pricingCount) {
        if (pricingCount > 0) {
            if (!this.pricingAlarmId) {
                let chk = this.startPricingAlarm();
                console.log('checkPricingAlarm: pricingAlarmId=' + chk);
                this.pricingAlarmId = chk;
            }
        } else {
            if (this.pricingAlarmId) {
                clearInterval(this.pricingAlarmId);
                this.pricingAlarmId = null;
            }
        }
    }

    updatePricingIcon(pricingCount) {
        console.log('pricingCount', pricingCount);
        $('#pricing-alarm').setGarageNavHeaderIconBadge(pricingCount);
    };

    startPricingAlarm() {
        console.log('startPricingAlarm');
        this.playPricingAlarm();
        return setInterval(this.playPricingAlarm, 180000);
    };

    playPricingAlarm() {
        if ($('.icon-alarm-ban').css('display') !== 'none') {
            return;
        }
        if ($('#pricing-alarm').text().trim() == '0' && this.pricingAlarmId) {
            clearInterval(this.pricingAlarmId);
            this.pricingAlarmId = null;
            return;
        }
        window._chime1 || (window._chime1 = new Audio("/assets/chime_1-787e24a1f371635a0f0f340a9303e917593ea68a634541b06f379e8507f48faf.mp3"));
        window._chime1.currentTime = 0;
        window._chime1.play().catch(error => console.log(error));
    };

    answered(data) {
        console.log('PricingCount:answered:' + data.type);
        //対象のelementがなければスルー
        if ($('#priced-alarm').length === 0) {
            return;
        }
        let pricedCount = parseInt(data.count);
        this.checkPricedAlarm(pricedCount);
        this.updatePricedIcon(pricedCount);
        this.showCountAlert();
    }

    checkPricedAlarm(pricedCount) {
        if (pricedCount > 0) {
            if (!this.pricedAlarmId) {
                let chk = this.startPricedAlarm();
                console.log('checkPricedAlarm: pricedAlarmId=' + chk);
                this.pricedAlarmId = chk;
            }
        } else {
            if (this.pricedAlarmId) {
                clearInterval(this.pricedAlarmId);
                this.pricedAlarmId = null;
            }
        }
    }

    updatePricedIcon(pricedCount) {
        console.log('pricedCount', pricedCount);
        $('#priced-alarm').setGarageNavHeaderIconBadge(pricedCount);
    };

    startPricedAlarm() {
        console.log('startPricedAlarm');
        this.playPricedAlarm();
        return setInterval(this.playPricedAlarm, 180000);
    };

    playPricedAlarm() {
        if ($('.icon-alarm-ban').css('display') !== 'none') {
            return;
        }
        if ($('#priced-alarm').text().trim() == '0' && this.pricedAlarmId) {
            clearInterval(this.pricedAlarmId);
            this.pricedAlarmId = null;
            return;
        }
        window._chime3 || (window._chime3 = new Audio("/assets/chime_3-8bb1b13e21ef4ede9ccc5411e6db6475b136a8bb6d59f9a902d92d4d542c6717.mp3"));
        window._chime3.currentTime = 0;
        window._chime3.play().catch(error => console.log(error));
    };

    showCountAlert() {
        if($('.navbar-header-icon-pricing').is(':visible')) {
            $('.count-alert').hide('slide', {direction: 'right'});
            let topPricing = $('.navbar-header-icon-pricing').position().top + 50;
            $('.count-alert-pricing').css('top', topPricing + 'px').show('slide', {direction: 'right'});
        }
        else {
            $('.count-alert-pricing').hide('slide', {direction: 'right'});
        }
    }
}
module.exports = PricingCount