import consumer from "./consumer"

const sessionsChannel = consumer.subscriptions.create("SessionsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log('sessions received:' + data);
    if(data.type == 'logout') {
      location.reload();
    }
    else if(data.type == 'login') {
      let staffSessionId = $(document.body).attr('data-staff-session-id');
      if (staffSessionId && !data.staff_session_ids.includes(parseInt(staffSessionId))) {
        location.reload();
      }
    }
    else if(data.type == 'away') {
      let staffId = parseInt($('#login-staff').data('id'));
      console.log('sessions received:away:' + staffId);
      $('#leave-panel').removeClass('hidden');
      $('#timecard-panel #button-break').removeClass('disabled');
    }
    else if(data.type == 'back') {
      let staffId = parseInt($('#login-staff').data('id'));
      console.log('sessions received:back:' + staffId);
      if(!$('#timecard-panel').hasClass('hidden'))
        $('#timecard-panel').addClass('hidden')
      Garage.shop_common.updateUrl({first_login: false})
      $('#leave-panel').addClass('hidden');
      $('#leave-panel #im-back-btn').removeClass('disabled');
    }
  }

});

