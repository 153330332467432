import QrScanner from 'qr-scanner';

export class ShakenScanner {
    static start() {
        console.log("start");
        let size = $('.camera-container').data('size');
        let red = $('.camera-container').data('red');
        let green = $('.camera-container').data('green');
        let blue = $('.camera-container').data('blue');
        let $videoElem = $('#video');
        let $fieldQr = $('#field-qr');
        let $checkContainer = $('#check-container');
        /**
         * 1//////
         * 2/- /063600326/240411/9503/E-
         * EP82/0056/- /- /0030/- /-
         *   /0/0/-/- /- /999999/01
         * 2/練馬　　５４　ま８３
         * ６１/1/EP82-0538835/4E/1
         *
         * K/71/軽自動車/乗用/自家用/003/4/-/-/-/1250/-/131/-/-
         * K/61/栃木県小山市萱橋７１１－１
         * K/51/株式会社　シュンインターナショナル
         * K/31/- /187860001/241021/2110/3BA-JB64W/0056/-/-/0047/- /- /-/-/-/- /- /999999
         * K/22/とちぎ　５８０み２２９７/1/JB64W-221182/R06A/1
         * K02 とちぎ　５８０み２２９７JB64W-221182 187860001
         */
        const qrs = [
            { car_type: "light", name: "qr-light-4", regexp: new RegExp("^K\/3[^\/]*\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/(.*)$")},
            { car_type: "light", name: "qr-light-5", regexp: new RegExp("^K\/2[^\/]*\/([^\/]*)\/([^\/]*)\/([^\/]*)\/?([^\/]*)\/?([^\/]*)$")},
            //{ name: "qr-1", regexp: new RegExp("^1\/\/\/\/\/\/$")},
            { car_type: "car", name: "qr-2", regexp: new RegExp("^2\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/?([^\/]*)$")},
            { car_type: "car", name: "qr-3", regexp: new RegExp("^([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/?([^\/]*)$")},
            { car_type: "car", name: "qr-4", regexp: new RegExp("^([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/?([^\/]*)$")},
            { car_type: "car", name: "qr-5", regexp: new RegExp("^2\/[^\/]*$")},
            { car_type: "car", name: "qr-6", regexp: new RegExp("^[^\/]*\/([^\/]*)\/([^\/]*)\/([^\/]*)\/([^\/]*)$")},
            //{ name: "qr-light-1", regexp: new RegExp("^K\/71\/([^\/]+?)\/([^\/]+?)\/([^\/]+?)\/([^\/]+?)\/([^\/]+?)\/([^\/]+?)\/([^\/]+?)\/([^\/]+?)\/([^\/]+?)\/([^\/]+?)\/([^\/]+?)\/([^\/]+?)\/([^\/]+?)$")},
            //{ name: "qr-light-2", regexp: new RegExp("^K\/61\/(.+)$")},
            //{ car_type: "light", name: "qr-light-3", regexp: new RegExp("^K\/51\/(.+)$")},
        ]

        let resultArray = [];
        let scanResult = function(result) {

            $checkContainer.css("border", "solid 2px #ff0000");
            setTimeout(() => {
                $checkContainer.css("border", "none");
            }, "100");

            //console.log('qr code:', $videoElem, $videoElem[0]);
            let resultText = result.data;
            $fieldQr.val(resultText);
            if (resultArray.includes(resultText)) return;
            resultArray.push(resultText)
            let hit = false;
            qrs.forEach(function(qr) {
                if (!hit && resultText.match(qr.regexp)) {
                    //document.getElementById(qr.name).append(resultText);
                    let icon = document.getElementById(`icon-${qr.name}`);
                    let field = document.getElementById(`field-${qr.name}`);
                    icon.classList.remove("text-default");
                    icon.classList.add("text-success");
                    field.value = resultText;
                    hit = true
                }
            });
            if(!hit) {
                console.log('NOHIT qr code:', result);
            }

            updateButtonState();

        }

        let $fields = $('.qr-field');
        $fields.on('change keyup mouseup input dp.hide', function(){
            console.log($(this).attr('name'), $(this).val());
            let iconId = 'icon-' + $(this).attr('name').replaceAll('_', '-');
            let $icon = $('#' + iconId);
            if ($(this).val().length > 0) {
                $icon.removeClass('text-default');
                $icon.addClass('text-success');
            }
            else {
                $icon.removeClass('text-success');
                $icon.addClass('text-default');
            }
            updateButtonState();
        });

        function updateButtonState() {
            let carFill = $('.qr-field-car').toArray().every(function(element){
                console.log($(element).val());
                return $(element).val();
            })
            let lightFill = $('.qr-field-light').toArray().every(function(element){
                console.log($(element).val());
                return $(element).val();
            });
            $('#button-qr').attr('disabled', !carFill && !lightFill);
        }

        const qrScanner = new QrScanner(
            $videoElem[0],
            scanResult,
            { returnDetailedScanResult: true,
                highlightScanRegion: true,
                calculateScanRegion: function(){
                    return {x: 130, y: 300, width: size, height: size, downScaledWidth: size, downScaledHeight: size }
                },
                highlightCodeOutline: true}
        );
        qrScanner.setGrayscaleWeights(red, green, blue, true);
        qrScanner.start();
    }
}

export default ShakenScanner;
