class PurchaseCount {
    constructor() {
        this.purchaseAlarmId = null;
    }
    received(data) {
        console.log('PurchaseCount:received:' + data);
        let result, shopId;
        //対象のelementがなければスルー
        if ($('#purchase-alarm').length === 0) {
            return;
        }
        shopId = parseInt($('#login-staff').data('shopId'));
        result = data[shopId];
        console.log('updatePurchaseAlarm: shopId=' + shopId);
        this.updatePurchaseIcon(result.purchase);
        this.updateReapproachIcon(result.reapproach);
        this.checkPurchaseAlarm(result);
        this.showCountAlert();
    }
    checkPurchaseAlarm(result) {
        var total;
        total = result.purchase.count + result.reapproach.count;
        if (total === 0) {
            if (this.purchaseAlarmId) {
                clearInterval(this.purchaseAlarmId);
                this.purchaseAlarmId = null;
            }
        } else {
            if (!this.purchaseAlarmId) {
                let chk = this.startPurchaseAlarm();
                console.log('checkPurchaseAlarm: purchaseAlarmId=' + chk);
                this.purchaseAlarmId = chk;
            }
        }
    };

    updatePurchaseIcon(purchaseCount) {
        console.log('purchaseCount', purchaseCount.count);
        $('#purchase-alarm').setGarageNavHeaderIconBadge(purchaseCount.count);
    };

    updateReapproachIcon(reapproachCount) {
        console.log('reapproachCount', reapproachCount.count);
        $('#reapproach-alarm').setGarageNavHeaderIconBadge(reapproachCount.count);
    };

    startPurchaseAlarm() {
        console.log('startPurchaseAlarm');
        this.playPurchaseAlarm();
        return setInterval(this.playPurchaseAlarm, 10000);
    };

    playPurchaseAlarm() {
        if ($('.icon-alarm-ban').css('display') !== 'none') {
            return;
        }
        if ($('.navbar-header-icon-purchase').is(':hidden') && this.purchaseAlarmId) {
            clearInterval(this.purchaseAlarmId);
            this.purchaseAlarmId = null;
            return;
        }
        window._chime2 || (window._chime2 = new Audio("/assets/chime_2-7ae7feb7cc2079ddfaa9e37c04c37d2179d472b355b8df9f0563d9a5c2511867.mp3"));
        window._chime2.currentTime = 0;
        window._chime2.play().catch(error => console.log(error));
    };

    showCountAlert() {
        if($('.navbar-header-icon-purchase').is(':visible')) {
            $('.count-alert').hide('slide', {direction: 'right'});
            let topPurchase = $('.navbar-header-icon-purchase').position().top + 50;
            $('.count-alert-purchase').css('top', topPurchase + 'px').show('slide', {direction: 'right'});
        }
        else {
            $('.count-alert-purchase').hide('slide', {direction: 'right'});
        }
    }
}
module.exports = PurchaseCount