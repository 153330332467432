import consumer from "./consumer"
import PurchaseCount from "../components/purchase_count.js.erb";

const alarmsChannel = consumer.subscriptions.create("AlarmsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log('received:' + data);

    switch (data.type) {
      case 'purchase_count':
        const PurchaseCount = require('../components/purchase_count.js.erb');
        new PurchaseCount().received(data);
        return;
      case 'sell_inquiry_count':
        const SellCount = require('../components/sell_count.js.erb');
        new SellCount().received(data);
        return;
      default:
        return;
    }

  }

});

